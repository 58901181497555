import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Profile from "../../../img/profile.webp";
import { handleScroll } from "../../../utils/Portfolio/handleScroll";
import getUserLanguage from "../../../utils/Portfolio/languageSelector";
import heroList from "../../../utils/Portfolio/heroList";

const Hero = () => {
  const { ref: heroRef, inView: heroInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const userLang = getUserLanguage();
  const languageData = heroList[userLang] || heroList.en;

  return (
    <div className="overflow-hidden pb-24">
      <div
        className="relative px-4 pt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-12"
        ref={heroRef}
      >
        <div className="flex flex-col-reverse lg:flex-row items-center justify-between w-full">
          <motion.div
            className="mb-8 lg:mb-0 lg:max-w-lg lg:pr-5"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: heroInView ? 1 : 0, y: heroInView ? 0 : 20 }}
            transition={{ duration: 0.8 }}
          >
            <div className="max-w-xl mb-6 text-left">
              <motion.h2
                className="font-sans text-4xl lg:text-5xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-tight max-w-lg mb-6"
                style={{ fontFamily: "Poppins, sans-serif" }}
                initial={{ opacity: 0 }}
                animate={{ opacity: heroInView ? 1 : 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                {languageData.greeting}{" "}
                <span className="inline-block text-blue-accent-700">
                  {languageData.name}
                </span>
              </motion.h2>
              <motion.p
                className="text-gray-700 text-base md:text-xl"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  lineHeight: "1.75",
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: heroInView ? 1 : 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                {languageData.description}
              </motion.p>
            </div>
            <motion.div
              className="flex flex-col lg:flex-row lg:justify-end lg:space-x-4 space-y-4 lg:space-y-0"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: heroInView ? 1 : 0, y: heroInView ? 0 : 20 }}
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              <a
                href="#aboutme"
                onClick={(e) => handleScroll(e, "#about-me")}
                className="flex items-center justify-center h-12 px-6 py-3 font-bold tracking-wide text-gray-800 transition duration-200 rounded border-4 border-gray-300 bg-white hover:bg-gray-100 text-lg"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                }}
              >
                {languageData.aboutMeText}
              </a>
              <a
                href="#contactme"
                onClick={(e) => handleScroll(e, "#contact-me")}
                className="flex items-center justify-center h-12 px-6 py-3 font-bold tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-accent-400 hover:bg-blue-accent-700 text-lg"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
                }}
              >
                {languageData.letsTalkText}
              </a>
            </motion.div>
          </motion.div>
          <motion.div
            className="flex items-center justify-center w-full lg:w-1/2 mb-8 lg:mb-0"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: heroInView ? 1 : 0, y: heroInView ? 0 : 20 }}
            transition={{ duration: 0.8, delay: 0.8 }}
            style={{ zIndex: 1 }}
          >
            <img
              className="w-1/2 sm:w-3/5 md:w-2/5 lg:w-3/5 xl:w-1/2 h-auto rounded-lg"
              src={Profile}
              alt="Profile"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
