import React from "react";

const FrontDecor = () => (
  <div className="absolute top-0 left-0 w-full h-24 z-10">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.5 19.6"
      preserveAspectRatio="none"
      className="w-full h-full opacity-33"
    >
      <path
        style={{ fill: "#60646f" }}
        d="M0 0L0 18.8 141.8 4.1 283.5 18.8 283.5 0z"
      />
      <path
        style={{ fill: "#94979e" }}
        d="M0 0L0 12.6 141.8 4 283.5 12.6 283.5 0z"
      />
      <path
        style={{ fill: "#b8b9be" }}
        d="M0 0L0 6.4 141.8 4 283.5 6.4 283.5 0z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M0 0L0 1.2 141.8 4 283.5 1.2 283.5 0z"
      />
    </svg>
  </div>
);

export default FrontDecor;
