import { useState, useRef, useEffect } from "react";
import { handleScroll } from "../../../utils/Portfolio/handleScroll";
import AutoHideHook from "../../Misc/AutoHideHook";
import getUserLanguage from "../../../utils/Portfolio/languageSelector";
import navbarList from "../../../utils/Portfolio/navBarList";

const NavBar = () => {
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 360);
  const [isVisible, setIsVisible] = useState(false);

  AutoHideHook(menuRef, setIsMenuOpen);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 360);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const userLang = getUserLanguage();
  const languageData = navbarList[userLang] || navbarList.en;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`relative z-20 transition-opacity duration-1000 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
      style={{
        background: "linear-gradient(to right, #111828 0%, #111828 100%)",
        minHeight: "80px",
      }}
    >
      <div className="px-4 py-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="relative flex items-center justify-between h-full">
          <a
            href="/"
            aria-label="Woobe-Studio"
            title="Woobe-Studio"
            className="inline-flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              fill="#2979ff"
              className="mr-2 text-3xl"
              style={{
                width: "40px",
                height: "40px",
                transform: "translateY(0px)",
              }}
            >
              <g>
                <circle cx="50" cy="11.517" r="9.017"></circle>
                <circle cx="22.788" cy="22.788" r="9.017"></circle>
                <circle cx="11.517" cy="50" r="9.017"></circle>
                <circle cx="22.788" cy="77.212" r="9.017"></circle>
                <circle cx="50" cy="88.483" r="9.017"></circle>
                <circle cx="77.212" cy="77.212" r="9.017"></circle>
                <circle cx="88.483" cy="50" r="9.017"></circle>
                <circle cx="77.212" cy="22.788" r="9.017"></circle>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 50 50"
                  to="360 50 50"
                  dur="4s"
                  repeatCount="indefinite"
                />
              </g>
            </svg>

            <span
              className={`ml-2 text-2xl lg:text-4xl font-bold tracking-wide text-white uppercase ${
                isSmallScreen ? "hidden" : "block"
              }`}
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "800",
                borderBottom: "4px solid #2979ff",
              }}
            >
              WOOBE-STUDIO
            </span>
            <span
              className={`ml-2 text-2xl lg:text-2xl font-bold tracking-wide text-white uppercase ${
                isSmallScreen ? "block" : "hidden"
              }`}
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "800",
                borderBottom: "4px solid #2979ff",
              }}
            >
              WS
            </span>
          </a>
          <button
            className="lg:hidden flex items-center p-2 text-white"
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <i
              className={`fas ${isMenuOpen ? "fa-times" : "fa-bars"} text-3xl`}
            ></i>
          </button>
          <ul className="items-center hidden space-x-6 lg:flex">
            {Object.keys(languageData).map((key, index) => (
              <li key={index}>
                <a
                  href={`#${key.toLowerCase()}`}
                  onClick={handleScroll}
                  className="font-semibold tracking-wide text-white transition-colors duration-200 hover:text-blue-accent-200"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                  }}
                >
                  {languageData[key]}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div
          ref={menuRef}
          className={`fixed inset-y-0 right-0 bg-gradient-to-r from-[#111828] to-[#111828] text-white w-60 transform ${
            isMenuOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300`}
          style={{ transition: "transform 0.3s ease", zIndex: 10 }}
        >
          <div className="flex items-center justify-between p-4">
            <span className="text-xl font-bold">Menu</span>
            <button
              className="text-3xl"
              onClick={toggleMenu}
              aria-label="Close menu"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
          <ul className="space-y-4 p-4">
            {Object.keys(languageData).map((key, index) => (
              <li key={index}>
                <a
                  href={`#${key.toLowerCase()}`}
                  onClick={handleScroll}
                  className="block font-semibold text-white transition-colors duration-200 hover:text-blue-accent-200"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                  }}
                >
                  {languageData[key]}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
