const aboutMeList = {
  title: {
    en: "About Me",
    pl: "O Mnie",
  },
  texts: {
    en: [
      "I am a third-year Computer Science student at the Białystok University of Technology. My main programming language is Python, and for the Santes project – a 2D pixel art top-down shooter for mobile systems – I used GDScript. Additionally, I optimized the source code of the Godot engine in C++ to improve the performance of the Santes game. Additionally, I developed custom plugins in Java for Google Play Services, Billing, and AdMob to integrate these features into my game.",
      "I founded the student group Data Pigeons, which focuses on data analysis and visualization in Python/R. I have experience creating web applications in Django and React.js, and at the university, I am leading the Świerk 2.0 project with a team of 15 people, using Jira and Confluence to manage the work.",
      "Outside of programming, I bike, run, and work out. I am interested in the Fallout series, healthy eating, and DIY projects.",
    ],
    pl: [
      "Jestem studentem trzeciego roku Informatyki na Politechnice Białostockiej. Moim głównym językiem programowania jest Python, a w projekcie Santes – 2D strzelance w pixel art z widokiem z góry na systemy mobilne – używałem GDScript. Ponadto, optymalizowałem kod źródłowy silnika Godot w C++, co miało na celu poprawę wydajności gry Santes. Dodatkowo stworzyłem własne wtyczki w Java do Google Play Services, Billing oraz AdMob, aby zintegrować te funkcje w grze.",
      "Założyłem studencką grupę Data Pigeons, zajmującą się analizą i wizualizacją danych w Pythonie/R. Mam doświadczenie w tworzeniu aplikacji webowych w Django i React.js, a na uczelni prowadzę projekt Świerk 2.0 z zespołem 15 osób, wykorzystując Jira i Confluence do zarządzania pracą.",
      "Poza programowaniem jeżdżę na rowerze, biegam i ćwiczę. Interesuję się serią Fallout, zdrowym odżywianiem i projektami DIY.",
    ],
  },
};

export default aboutMeList;
