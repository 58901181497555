const aboutGodotList = {
  title: {
    en: "About The Game",
    pl: "O Grze",
  },
  texts: {
    en: [
      "I’m working on a game called Santes – a 2D pixel art top-down shooter in Godot, using GDScript and C++. The game offers dynamic gameplay where players fight enemies, upgrade weapons, armor, and use special abilities to progress through different levels. It features boss fights, a pet companion system, and engaging combat mechanics.",
      "All the graphics, sounds, and music were created by me, providing a unique and personal touch to the game.",
    ],
    pl: [
      "Pracuję nad grą Santes – 2D pixel artowym top-down shooterem w Godot, wykorzystując GDScript i C++. Gra oferuje dynamiczną rozgrywkę, w której gracze walczą z wrogami, ulepszają broń, pancerz i korzystają ze specjalnych zdolności, by przejść przez różne poziomy. Zawiera walki z bossami, system towarzyszy zwierząt i angażującą mechanikę walki.",
      "Wszystkie grafiki, dźwięki i muzyka zostały stworzone przeze mnie, co nadaje grze unikalny i osobisty charakter.",
    ],
  },
};

export default aboutGodotList;
