import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import aboutMeList from "../../../utils/Portfolio/aboutMeList";
import getUserLanguage from "../../../utils/Portfolio/languageSelector";
import Picture from "../../../img/about.webp";
import FrontDecore from "../../Misc/FrontDecore";

const AboutMe = () => {
  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: contentRef, inView: contentInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const language = getUserLanguage();
  const aboutMeData = aboutMeList;
  const title = aboutMeData.title[language] || aboutMeData.title.en;
  const texts = aboutMeData.texts[language] || aboutMeData.texts.en;

  return (
    <div
      id="aboutme"
      className="relative flex justify-center items-center py-16"
      style={{
        background: "linear-gradient(to right, #111828 0%, #111828 100%)",
      }}
    >
      <FrontDecore />

      <div className="relative z-11 flex flex-col items-center max-w-screen-xl px-4 mx-auto lg:px-8">
        <motion.div
          ref={titleRef}
          className="text-center mb-10 lg:mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : 20 }}
          transition={{ duration: 0.6 }}
        >
          <h2
            className="text-4xl font-bold leading-none tracking-tight text-white mb-8 sm:text-4xl"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            <span className="relative inline-block">
              <span className="relative">{title}</span>
            </span>
          </h2>
        </motion.div>

        <motion.div
          ref={contentRef}
          className="flex flex-col lg:flex-row items-center lg:justify-center gap-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{
            opacity: contentInView ? 1 : 0,
            y: contentInView ? 0 : 20,
          }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          <div className="w-full max-w-xs lg:max-w-md flex justify-center mb-6 lg:mb-0">
            <img
              src={Picture}
              alt="About Me"
              className="w-full h-auto object-cover rounded-lg shadow-md"
            />
          </div>
          <div className="w-full lg:w-1/2 text-white text-xl">
            <div className="flex flex-col items-center lg:items-start">
              {texts.map((text, index) => (
                <p key={index} className="mb-4 text-center lg:text-left">
                  {text}
                </p>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutMe;
