import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import languagesList from "../../../utils/Portfolio/languagesList";
import getUserLanguage from "../../../utils/Portfolio/languageSelector";

const LanguageSection = ({ title, proficiency, description, delay }) => {
  const { ref: langRef, inView: langInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={langRef}
      className="flex flex-col items-center bg-white border border-gray-300 rounded-lg shadow-md overflow-hidden"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: langInView ? 1 : 0, y: langInView ? 0 : 20 }}
      transition={{ duration: 0.6, delay }}
    >
      <div className="p-6 text-center">
        <h3
          className="text-xl font-semibold mb-2"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          {title}
        </h3>
        <p className="text-gray-500 mb-4">{proficiency}</p>
        <p className="text-gray-700">{description}</p>
      </div>
    </motion.div>
  );
};

const Languages = () => {
  const { ref: sectionRef, inView: sectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [language, setLanguage] = useState("en");

  useEffect(() => {
    const langCode = getUserLanguage();
    if (languagesList.title[langCode]) {
      setLanguage(langCode);
    } else {
      setLanguage("en");
    }
  }, []);

  const title = languagesList.title[language] || languagesList.title["en"];
  const languages =
    languagesList.languages[language] || languagesList.languages["en"];

  return (
    <div
      id="languages"
      className="px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-20 lg:px-6 lg:py-16"
    >
      <motion.div
        ref={titleRef}
        className="max-w-xl mb-8 md:mx-auto text-center lg:max-w-2xl md:mb-10"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : 20 }}
        transition={{ duration: 0.6 }}
      >
        <h2
          className="text-2xl sm:text-3xl lg:text-3xl font-bold leading-tight text-gray-900 text-center"
          style={{
            fontFamily: "Poppins, sans-serif",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          }}
        >
          <span className="relative inline-block">
            <span className="relative" style={{ color: "#1E2A38" }}>
              {title}
            </span>
          </span>
        </h2>
      </motion.div>

      <motion.div
        ref={sectionRef}
        className="grid grid-cols-1 sm:grid-cols-2 gap-6 row-gap-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: sectionInView ? 1 : 0 }}
        transition={{ duration: 0.8 }}
      >
        {languages.map((language, index) => (
          <LanguageSection
            key={index}
            title={language.title}
            proficiency={language.proficiency}
            description={language.description}
            delay={index * 0.2}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default Languages;
