const aboutTestingList = {
  title: {
    en: "Performance analysis and debugging",
    pl: "Analiza wydajności i debugowanie",
  },
  description: {
    en: "I tested and debugged the game using the profiler in Godot, ensuring there were no memory leaks. I optimized the number of objects being created and improved overall performance.",
    pl: "Testowałem i debugowałem grę z użyciem profilera w Godot, dbając o to, żeby nie było wycieków pamięci. Optymalizowałem ilość tworzonych obiektów i poprawiałem wydajność.",
  },
};

export default aboutTestingList;
