import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const UnityGameLink = () => {
  const isPolish = navigator.language.startsWith("pl");
  const buttonText = isPolish ? "Pobierz na" : "Get it on";

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      id="itch-io"
      className="relative flex flex-col items-center bg-white py-16"
      initial={{ opacity: 0, y: 30 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
      transition={{ duration: 0.8 }}
    >
      <motion.div
        className="text-center mb-10"
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ duration: 0.6 }}
      >
        <h2
          className="text-4xl font-bold tracking-tight text-gray-900"
          style={{
            fontFamily: "Poppins, sans-serif",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          }}
        >
          {buttonText}
        </h2>
      </motion.div>

      <motion.a
        href="https://woobe-studio.itch.io/westernshooter"
        target="_blank"
        rel="noopener noreferrer"
        className="mt-4"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={inView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/7/79/Itch.io_logo.svg"
          alt="Get it on Itch.io"
          className="h-16 md:h-20"
        />
      </motion.a>
    </motion.div>
  );
};

export default UnityGameLink;
