const aboutMediaUsedList = {
  title: {
    en: "Service Integration",
    pl: "Integracja usług",
  },
  description: {
    en: "I integrated AdMob ads, Google Play payments, and a data storage system both locally and in the cloud, focusing on data security and application performance optimization.",
    pl: "Zintegrowałem reklamy AdMob, płatności Google Play oraz system przechowywania danych lokalnie i w chmurze, koncentrując się na bezpieczeństwie danych i optymalizacji działania aplikacji.",
  },
};

export default aboutMediaUsedList;
