import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import aboutBlendTreeList from "../../../utils/GameDev/aboutBlendTreeList";
import getUserLanguage from "../../../utils/Portfolio/languageSelector";
import blendTreeImage from "../../../img/blend_tree.webp";

const AnimationUnity = () => {
  const { ref: sectionRef, inView: sectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const language = getUserLanguage();
  const title =
    aboutBlendTreeList.title[language] || aboutBlendTreeList.title.en;
  const description =
    aboutBlendTreeList.description[language] ||
    aboutBlendTreeList.description.en;

  return (
    <div className="relative flex justify-center items-center py-16 bg-white">
      <div className="relative z-11 flex flex-col lg:flex-row-reverse items-center max-w-screen-xl px-4 mx-auto lg:px-8">
        {/* Sekcja prawa - Opis */}
        <motion.div
          ref={sectionRef}
          className="lg:w-1/2 text-center lg:text-right mb-10 lg:mb-0 
                     max-w-xl ml-auto break-words"
          initial={{ opacity: 0, x: 20 }}
          animate={{
            opacity: sectionInView ? 1 : 0,
            x: sectionInView ? 0 : 20,
          }}
          transition={{ duration: 0.6 }}
        >
          <h2
            className="text-3xl font-bold text-gray-900 mb-6"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            {title}
          </h2>
          <p className="text-gray-700 text-xl mb-6">{description}</p>
        </motion.div>

        {/* Sekcja lewa - Obrazek */}
        <motion.div
          className="lg:w-1/2 flex justify-center"
          initial={{ opacity: 0, x: -20 }}
          animate={{
            opacity: sectionInView ? 1 : 0,
            x: sectionInView ? 0 : -20,
          }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          <img
            src={blendTreeImage}
            alt="Code Example"
            className="w-full max-w-xl rounded-lg shadow-lg"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default AnimationUnity;
