const heroList = {
  en: {
    greeting: "Hi, I'm",
    name: "Michal",
    description:
      "A software developer based in Białystok, Poland, I build impactful digital solutions tailored to your needs. Explore my portfolio and let’s discuss how I can help with your project!",
    aboutMeText: "About Me",
    letsTalkText: "Let's Talk",
  },
  pl: {
    greeting: "Hej, tu",
    name: "Michał",
    description:
      "Jestem programistą z Białegostoku w Polsce, tworzącym znaczące rozwiązania cyfrowe dostosowane do Twoich potrzeb. Zobacz moje portfolio i porozmawiajmy, jak mogę pomóc w Twoim projekcie!",
    aboutMeText: "O mnie",
    letsTalkText: "Porozmawiajmy",
  },
};

export default heroList;
