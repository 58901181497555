import MailIcon from "../../img/mail.webp";
import LinkedinIcon from "../../img/linkedin.webp";
import GitHubIcon from "../../img/github.webp";
import ResumeIcon from "../../img/resume.webp";

const contactMeList = {
  title: {
    en: "Contact Me",
    pl: "Skontaktuj się ze mną",
  },
  misc: {
    email: {
      title: "Email",
      link: "mailto:woobe-studio@proton.me",
      icon: MailIcon,
    },
    linkedin: {
      title: "LinkedIn",
      link: "https://www.linkedin.com/in/wozniakdev/",
      icon: LinkedinIcon,
    },
    github: {
      title: "GitHub",
      link: "https://github.com/woobe-studio",
      icon: GitHubIcon,
    },
    resume: {
      title: {
        en: "Resume",
        pl: "CV",
      },
      links: {
        en: "/documents/MichalWozniak_ResumeEnglish.pdf",
        pl: "/documents/MichalWozniak_ResumePolish.pdf",
      },
      icon: ResumeIcon,
    },
  },
  contacts: {
    en: {
      email: {
        description: "woobe-studio@proton.me",
      },
      linkedin: {
        description: "Let's connect.",
      },
      github: {
        description: "Check out my repos.",
      },
      resume: {
        description: "Download my resume.",
      },
    },
    pl: {
      email: {
        description: "woobe-studio@proton.me",
      },
      linkedin: {
        description: "Połączmy się.",
      },
      github: {
        description: "Sprawdź moje repozytoria.",
      },
      resume: {
        description: "Pobierz moje CV.",
      },
    },
  },
};

export default contactMeList;
