const aboutUnityList = {
  title: {
    en: "About The Game",
    pl: "O Grze",
  },
  texts: {
    en: [
      "I started developing a western game in Unity in 2020, dedicating six months to the project. The game features mechanics such as a health bar, interactive buildings, revolver and shotgun shooting, and a dynamic ammo bar. Players can use joystick controllers and buttons, enriching the gameplay.",
    ],
    pl: [
      "Zacząłem rozwijać grę westernową w Unity w 2020 roku, poświęcając na ten projekt sześć miesięcy. Gra oferuje mechaniki takie jak pasek życia, interaktywne budynki, strzelanie z rewolweru i strzelby, a także dynamiczny pasek amunicji. Gracze mogą korzystać z kontrolerów joysticka i przycisków, co wzbogaca rozgrywkę.",
    ],
  },
};

export default aboutUnityList;
