import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Hero from "./components/Portfolio/Hero";
import AboutMe from "./components/Portfolio/AboutMe";
import GameDevLink from "./components/Portfolio/GameDevLink";
import GoToTheTop from "./components/Misc/GoToTheTop";
import Skills from "./components/Portfolio/Skills";
import Languages from "./components/Portfolio/Languages";
import Certificates from "./components/Portfolio/Certificates";
import Projects from "./components/Portfolio/Projects";
import Experiences from "./components/Portfolio/Experience";
import AdditionalExperiences from "./components/Portfolio/AdditionalExperience";
import Footer from "./components/Footer";
import ContactMe from "./components/Portfolio/ContactMe";
import NavBar from "./components/Portfolio/NavBar";
import TabInfo from "./components/Misc/TabInfo";
import getUserLanguage from "./utils/Portfolio/languageSelector";
import appTranslations from "./utils/Portfolio/appLanguageList";
import LoadingScreen from "./components/Misc/LoadingScreen";

import GameScreenshots from "./components/GameDev/GameScreenshots";
import GameTrailer from "./components/GameDev/GameTrailer";
import GameGodot from "./components/GameDev/GameGodot";
import Title from "./components/GameDev/Title";
import UnityGameDevLink from "./components/GameDev/UnityLink";
import UnityGameScreenshots from "./components/Unity/GameScreenshots";
import GameUnity from "./components/Unity/GameUnity";
import GooglePlayButton from "./components/GameDev/GooglePlay";
import CodeShowcase from "./components/GameDev/CodeShowcase";
import TestShowcase from "./components/GameDev/TestShowcase";
import MediaUsed from "./components/GameDev/MediaUsed";
import CodeUnity from "./components/Unity/CodeUnity";
import AnimationUnity from "./components/Unity/AnimationUnity";
import UnityGameLink from "./components/Unity/UnityGameLink";

function App() {
  const userLanguage = getUserLanguage();
  const { newTitle, originalTitle } =
    appTranslations[userLanguage] || appTranslations.en;

  TabInfo(newTitle, originalTitle);

  return (
    <Router>
      <LoadingScreen>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <NavBar />
                <Hero />
                <AboutMe />
                <GameDevLink />
                <Skills />
                <Languages />
                <Certificates />
                <Projects />
                <Experiences />
                <AdditionalExperiences />
                <ContactMe />
                <Footer />
                <GoToTheTop />
              </>
            }
          />
          <Route
            path="/gamedev"
            element={
              <>
                <Title title="Santes" href="/" />
                <GameTrailer />
                <GooglePlayButton />
                <GameGodot />
                <CodeShowcase />
                <TestShowcase />
                <MediaUsed />
                <UnityGameDevLink />
                <GameScreenshots />
                <Footer />
                <GoToTheTop />
              </>
            }
          />
          <Route
            path="/gamedev/unity"
            element={
              <>
                <Title title="WesternShooter" href="/gamedev" />
                <UnityGameScreenshots />
                <UnityGameLink />
                <GameUnity />
                <CodeUnity />
                <AnimationUnity />
                <Footer />
                <GoToTheTop />
              </>
            }
          />
        </Routes>
      </LoadingScreen>
    </Router>
  );
}

export default App;
