import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import contactMeList from "../../../utils/Portfolio/contactMeList";
import getUserLanguage from "../../../utils/Portfolio/languageSelector";

const ContactCard = ({
  link,
  icon,
  title,
  description,
  delay,
  language,
  openModal,
}) => {
  const { ref: cardRef, inView: cardInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [copied, setCopied] = useState(false);

  const handleClick = (e) => {
    if (title === "Resume" || title === "CV") {
      e.preventDefault();
      openModal();
    } else if (title === "Email") {
      e.preventDefault();
      navigator.clipboard.writeText(link.replace("mailto:", "")).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Hide notification after 2 sec
      });
    }
  };

  return (
    <motion.div
      ref={cardRef}
      className="group relative"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: cardInView ? 1 : 0, y: cardInView ? 0 : 20 }}
      transition={{ duration: 0.6, delay }}
    >
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        aria-label={title}
        className="block"
        onClick={handleClick}
      >
        <div
          className="relative p-6 bg-gray-100 border-2 border-gray-300 rounded-lg shadow-md hover:bg-gray-200 transition"
          style={{ width: "300px" }}
        >
          <div className="flex items-center mb-4">
            <img src={icon} alt={title} className="w-12 h-12 mr-4" />
            <h6 className="font-semibold text-xl">{title}</h6>
          </div>
          <p className="text-lg text-gray-800">{description}</p>
        </div>
      </a>

      {/* Notification */}
      {copied && (
        <div className="absolute top-0 right-0 bg-green-500 text-white px-4 py-2 rounded-lg shadow-md">
          {language === "pl" ? "Skopiowano email!" : "Email copied!"}
        </div>
      )}
    </motion.div>
  );
};

const ContactMe = () => {
  const { ref: sectionRef, inView: sectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [language, setLanguage] = useState("en");
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const langCode = getUserLanguage();
    if (contactMeList.title[langCode]) {
      setLanguage(langCode);
    } else {
      setLanguage("en");
    }
  }, []);

  const handleDownload = (lang) => {
    const resumeLink =
      lang === "pl"
        ? contactMeList.misc.resume.links.pl
        : contactMeList.misc.resume.links.en;
    window.location.href = resumeLink;
    setModalOpen(false);
  };

  const { title, contacts, misc } = contactMeList;
  const currentContacts = contacts[language];

  return (
    <div
      id="contactme"
      className="relative px-4 py-16 mx-auto sm:px-6 md:px-12 lg:px-16 lg:py-20"
    >
      <div className="absolute top-0 left-0 right-0 h-16 bg-gradient-to-b from-transparent via-gray-100 to-gray-200 opacity-90 shadow-md z-10"></div>
      <motion.div
        ref={sectionRef}
        className="relative max-w-5xl mx-auto pt-16 pb-12 lg:pt-24 lg:pb-20"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: sectionInView ? 1 : 0, y: sectionInView ? 0 : 20 }}
        transition={{ duration: 0.6 }}
      >
        <div className="text-center mb-12">
          <h2
            className="text-4xl font-bold leading-tight tracking-tight text-gray-900 sm:text-4xl"
            style={{
              fontFamily: "Poppins, sans-serif",
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
            }}
          >
            <span className="relative inline-block">
              <span className="relative" style={{ color: "#1E2A38" }}>
                {title[language]}
              </span>
            </span>
          </h2>
        </div>
        <div className="flex flex-wrap justify-center gap-8">
          {Object.keys(currentContacts).map((key, index) => {
            const { title, links, icon } = misc[key];
            const description = currentContacts[key].description;
            const link = links ? links[language] : misc[key].link;

            return (
              <ContactCard
                key={key}
                link={link}
                icon={icon}
                title={typeof title === "string" ? title : title[language]}
                description={description}
                delay={index * 0.2}
                language={language}
                openModal={() => setModalOpen(true)}
              />
            );
          })}
        </div>
      </motion.div>

      {/* Modal for Resume Download */}
      {modalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20"
          onClick={() => setModalOpen(false)}
        >
          <div
            className="bg-white p-6 rounded-lg shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg font-bold mb-4">
              {language === "pl"
                ? "Wybierz wersję CV"
                : "Choose Resume Version"}
            </h3>
            <div className="flex justify-center gap-4">
              <button
                onClick={() => handleDownload("pl")}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
              >
                PL
              </button>
              <button
                onClick={() => handleDownload("en")}
                className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
              >
                EN
              </button>
            </div>
            <button
              onClick={() => setModalOpen(false)}
              className="block mt-4 mx-auto text-gray-600 underline hover:text-gray-800"
            >
              {language === "pl" ? "Anuluj" : "Cancel"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactMe;
