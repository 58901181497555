const aboutBlendTreeList = {
  title: {
    en: "Animations",
    pl: "Animacje",
  },
  description: {
    en: "Using Blend Tree in Unity for smooth transitions between animations based on player input, ensuring natural movement and actions.",
    pl: "Używam Blend Tree w Unity do płynnych przejść między animacjami, w zależności od działań gracza, zapewniając naturalny ruch i reakcje postaci.",
  },
};

export default aboutBlendTreeList;
