import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import getUserLanguage from "../../../utils/Portfolio/languageSelector";
import gameDevLinkList from "../../../utils/Portfolio/GameDevLinkList";
import EnginesLogo from "../../../img/engines.webp";

const GameDevLink = () => {
  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const language = getUserLanguage();
  const content = gameDevLinkList[language] || gameDevLinkList.en;

  const handleButtonClick = () => {
    window.location.href = "/gamedev";
  };

  return (
    <div
      id="gamedevlink"
      className="relative flex flex-col justify-center items-center py-16 bg-white"
    >
      {/* Title Section */}
      <div className="relative z-11 flex flex-col items-center max-w-screen-xl px-4 mx-auto lg:px-8">
        <motion.div
          ref={titleRef}
          className="text-center mb-10 lg:mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : 20 }}
          transition={{ duration: 0.6 }}
        >
          <h2
            className="text-4xl font-bold leading-none tracking-tight text-gray-900 mb-8 sm:text-4xl"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            <span className="relative inline-block">
              <span className="relative">{content.title}</span>
            </span>
          </h2>
        </motion.div>

        {/* Description and Button */}
        <motion.div
          className="flex flex-col items-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : 20 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          <p className="text-gray-700 text-xl mb-6 text-center">
            {content.description}
          </p>
          {/* Logos - Centered below title */}
          <div className="flex flex-row items-center justify-center w-full max-w-screen-xl px-4 mt-8 mb-8 space-x-8">
            <img
              src={EnginesLogo}
              alt="Engines Logo"
              className="h-auto w-auto max-h-40"
            />
          </div>
          <button
            className="flex items-center justify-center h-16 px-8 py-4 font-bold tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-accent-400 hover:bg-blue-accent-700 text-xl"
            onClick={handleButtonClick}
          >
            {content.buttonText}
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default GameDevLink;
