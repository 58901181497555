const aboutCodeUnityList = {
  title: {
    en: "Unity Development",
    pl: "Tworzenie w Unity",
  },
  description: {
    en: "I developed a game in Unity using C#. I structured the project by creating custom classes to manage gameplay mechanics, player movement, UI elements, and a day-night system that dynamically changes the environmental lighting.",
    pl: "Stworzyłem grę w Unity, wykorzystując C#. Strukturyzowałem projekt, tworząc własne klasy do zarządzania mechaniką rozgrywki, ruchem gracza, elementami interfejsu oraz systemem dnia i nocy, który dynamicznie zmienia oświetlenie otoczenia.",
  },
};

export default aboutCodeUnityList;
