const aboutCodeList = {
  title: {
    en: "Engine customization",
    pl: "Dostosowanie silnika",
  },
  description: {
    en: "I made changes to the Godot engine by creating and modifying custom nodes and functions. Additionally, I optimized the code to improve performance and reduced the Android app size by 50%. The compilation process was carried out using SCons, applying various optimization methods to reduce code size and minimize dependencies.",
    pl: "Wprowadziłem zmiany w silniku Godot, tworząc i modyfikując specjalne węzły oraz funkcje. Dodatkowo, optymalizowałem kod, poprawiając wydajność i zmniejszając wagę aplikacji na Androida o 50%. Proces kompilacji realizowałem przy użyciu SCons, stosując różne metody optymalizacyjne w celu zmniejszenia rozmiaru kodu i minimalizacji zależności.",
  },
};

export default aboutCodeList;
