const unityDevList = {
  en: {
    title: "Want to know about my Unity game development?",
    description:
      "I am passionate about game development in Unity, and I'd love to share more details with you.",
    buttonText: "Learn More",
  },
  pl: {
    title: "Chcesz dowiedzieć się o moim tworzeniu gier w Unity?",
    description:
      "Jestem pasjonatem tworzenia gier w Unity i chciałbym podzielić się z tobą dodatkowymi szczegółami.",
    buttonText: "Dowiedz się więcej",
  },
};

export default unityDevList;
