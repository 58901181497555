function TabInfo(newTitle, originalTitle) {
  const handleVisibilityChange = () => {
    if (document.hidden) {
      document.title = newTitle;
    } else {
      document.title = originalTitle;
    }
  };

  document.addEventListener("visibilitychange", handleVisibilityChange);
  document.title = originalTitle;

  return () => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  };
}

export default TabInfo;
