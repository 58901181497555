const techBadges = [
  // Programming Languages
  {
    name: "Python",
    color: "4B8BBE",
    logo: "python",
    category: "Programming Languages",
  },
  {
    name: "Java",
    color: "007396",
    logo: "openjdk",
    category: "Programming Languages",
  },
  {
    name: "C++",
    color: "00599C",
    logo: "cplusplus",
    category: "Programming Languages",
  },
  {
    name: "Kotlin",
    color: "F18E33",
    logo: "kotlin",
    category: "Programming Languages",
  },
  {
    name: "JavaScript",
    color: "F7DF1E",
    logo: "javascript",
    category: "Programming Languages",
  },
  { name: "R", color: "276DC3", logo: "r", category: "Programming Languages" },
  {
    name: "C#",
    color: "239120",
    logo: "unity",
    category: "Programming Languages",
  },
  {
    name: "Dart",
    color: "0175C2",
    logo: "dart",
    category: "Programming Languages",
  },

  // FrontEnd
  { name: "React", color: "61DAFB", logo: "react", category: "FrontEnd" },
  { name: "HTML", color: "E44D26", logo: "html5", category: "FrontEnd" },
  { name: "CSS", color: "264DE4", logo: "css3", category: "FrontEnd" },
  {
    name: "TailwindCSS",
    color: "06B6D4",
    logo: "tailwindcss",
    category: "FrontEnd",
  },
  { name: "Qt", color: "41CD52", logo: "qt", category: "FrontEnd" },
  { name: "NextJs", color: "000000", logo: "next.js", category: "FrontEnd" },
  { name: "Flutter", color: "02569B", logo: "flutter", category: "FrontEnd" },
  { name: "APIs", color: "F7E03C", logo: "strapi", category: "FrontEnd" },
  { name: "OAuth2", color: "E54B4F", logo: "auth0", category: "FrontEnd" },
  // BackEnd
  { name: "Django", color: "092E20", logo: "django", category: "BackEnd" },
  {
    name: "SpringBoot",
    color: "6DB33F",
    logo: "springboot",
    category: "Framework",
  },
  { name: "Flask", color: "000000", logo: "flask", category: "BackEnd" },

  { name: "Dash", color: "2496ED", logo: "dash", category: "BackEnd" },
  { name: "NodeJS", color: "8CC84B", logo: "node.js", category: "BackEnd" },
  { name: "Express.js", color: "000000", logo: "express", category: "BackEnd" },

  // Databases
  { name: "MySQL", color: "4479A1", logo: "mysql", category: "Databases" },
  {
    name: "PostgreSQL",
    color: "4169E1",
    logo: "postgresql",
    category: "Databases",
  },
  { name: "SQLite", color: "003B57", logo: "sqlite", category: "Databases" },

  // Tools
  {
    name: "VSCode",
    color: "007ACC",
    logo: "codeium",
    category: "Tools",
  },
  { name: "Linux", color: "FCC624", logo: "linux", category: "Tools" },
  { name: "Git", color: "F05032", logo: "git", category: "Tools" },
  {
    name: "PowerShell",
    color: "003B57",
    logo: "gnome-terminal",
    category: "Tools",
  },
  { name: "Docker", color: "2496ED", logo: "docker", category: "Tools" },
  {
    name: "JUnit5",
    color: "25A162",
    logo: "JUnit5",
    category: "Testing",
  },
  { name: "GitHub", color: "181717", logo: "github", category: "Tools" },
  { name: "JetBrains", color: "000000", logo: "jetbrains", category: "Tools" },
  {
    name: "Android Studio",
    color: "3DDC84",
    logo: "android-studio",
    category: "Tools",
  },
  { name: "VMware", color: "607078", logo: "vmware", category: "Tools" },
  {
    name: "Visual Studio",
    color: "5C2D91",
    logo: "cnet",
    category: "Tools",
  },
  {
    name: "STM32CubeIDE",
    color: "003D7A",
    logo: "stmicroelectronics",
    category: "Tools",
  },

  { name: "Neovim", color: "57A143", logo: "neovim", category: "Tools" },
  { name: "Gradle", color: "02303A", logo: "gradle", category: "Tools" },
  { name: "Windows 11", color: "0078D4", logo: "windows", category: "Tools" },

  // Cloud and Hosting
  {
    name: "Firebase",
    color: "FFCA28",
    logo: "firebase",
    category: "Cloud and Hosting",
  },
  {
    name: "Vercel",
    color: "000000",
    logo: "vercel",
    category: "Cloud and Hosting",
  },
  {
    name: "Google Play",
    color: "34A853",
    logo: "google-play",
    category: "Cloud and Hosting",
  },
  {
    name: "Google Play Billing",
    color: "FF5F4F",
    logo: "googlepay",
    category: "Cloud and Hosting",
  },
  {
    name: "AdMob",
    color: "F7C600",
    logo: "google-play",
    category: "Cloud and Hosting",
  },
  {
    name: "GDScript",
    color: "8D3F5C",
    logo: "godot-engine",
    category: "Tools",
  }, // Updated color
  {
    name: "Godot Engine",
    color: "2496ED",
    logo: "godot-engine",
    category: "Tools",
  },
  { name: "Sphinx", color: "F7C942", logo: "sphinx", category: "Tools" },
  { name: "JSON", color: "F7E03C", logo: "json", category: "Tools" },
  { name: "SMTP", color: "FF6F61", logo: "protonmail", category: "Tools" },
  // Collaboration Tools
  { name: "Jira", color: "0052CC", logo: "jira", category: "Tools" },
  {
    name: "Confluence",
    color: "172B4D",
    logo: "confluence",
    category: "Tools",
  },
];

export default techBadges;
