import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import aboutUnityList from "../../../utils/GameDev/aboutUnityList";
import getUserLanguage from "../../../utils/Portfolio/languageSelector";
import Picture from "../../../img/unity.png";
import FrontDecore from "../../Misc/FrontDecore";

const GameUnity = () => {
  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: contentRef, inView: contentInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const language = getUserLanguage();
  const aboutUnityData = aboutUnityList;
  const title = aboutUnityData.title[language] || aboutUnityData.title.en;
  const texts = aboutUnityData.texts[language] || aboutUnityData.texts.en;

  return (
    <div
      id="aboutme"
      className="relative flex justify-center items-center py-16"
      style={{
        background: "linear-gradient(to right, #111828 0%, #111828 100%)",
      }}
    >
      <FrontDecore />

      <div className="relative z-11 flex flex-col items-center max-w-screen-xl px-4 mx-auto lg:px-8">
        <motion.div
          ref={titleRef}
          className="text-center mb-10 lg:mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : 20 }}
          transition={{ duration: 0.6 }}
        >
          <h2
            className="text-4xl font-bold leading-none tracking-tight text-white mb-8 sm:text-4xl"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            {(() => {
              const words = title.split(" ");
              const shouldSplit =
                words.length > 2 || words.some((word) => word.length > 5);

              return shouldSplit ? (
                <>
                  <span className="relative block">{words[0]}</span>
                  <span className="relative block">
                    {words.slice(1).join(" ")}
                  </span>
                </>
              ) : (
                <span className="relative">{title}</span>
              );
            })()}
          </h2>
        </motion.div>

        <motion.div
          ref={contentRef}
          className="flex flex-col lg:flex-row items-center lg:justify-center gap-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{
            opacity: contentInView ? 1 : 0,
            y: contentInView ? 0 : 20,
          }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          <div className="w-full max-w-xs lg:max-w-md flex justify-center mb-6 lg:mb-0">
            <img
              src={Picture}
              alt="About the game"
              className="w-full h-auto object-cover rounded-lg"
            />
          </div>
          <div className="w-full lg:w-1/2 text-white text-xl">
            <div className="flex flex-col items-center lg:items-start">
              {texts.map((text, index) => (
                <p key={index} className="mb-4 text-center lg:text-left">
                  {text}
                </p>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default GameUnity;
