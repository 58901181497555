import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import aboutCodeUnityList from "../../../utils/GameDev/aboutCodeUnityList"; // Import translations
import getUserLanguage from "../../../utils/Portfolio/languageSelector"; // Import language selector
import unityCodeImage from "../../../img/unity_code.webp"; // Replace with actual image path

const CodeUnity = () => {
  const { ref: sectionRef, inView: sectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const language = getUserLanguage();
  const title =
    aboutCodeUnityList.title[language] || aboutCodeUnityList.title.en;
  const description =
    aboutCodeUnityList.description[language] ||
    aboutCodeUnityList.description.en;

  return (
    <div className="relative flex justify-center items-center py-16 bg-white">
      <div className="relative z-11 flex flex-col lg:flex-row items-center max-w-screen-xl px-4 mx-auto lg:px-8">
        {/* Left Section - Description */}
        <motion.div
          ref={sectionRef}
          className="lg:w-1/2 text-center lg:text-left mb-10 lg:mb-0"
          initial={{ opacity: 0, x: -20 }}
          animate={{
            opacity: sectionInView ? 1 : 0,
            x: sectionInView ? 0 : -20,
          }}
          transition={{ duration: 0.6 }}
        >
          <h2
            className="text-3xl font-bold text-gray-900 mb-6"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            {title}
          </h2>
          <p className="text-gray-700 text-xl mb-6">{description}</p>
        </motion.div>

        {/* Right Section - Code Image */}
        <motion.div
          className="lg:w-1/2 flex justify-center"
          initial={{ opacity: 0, x: 20 }}
          animate={{
            opacity: sectionInView ? 1 : 0,
            x: sectionInView ? 0 : 20,
          }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          <img
            src={unityCodeImage}
            alt="Code Example"
            className="w-full max-w-md rounded-lg shadow-lg"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default CodeUnity;
